<template>
	<div>
		<div class="sub-tit" ref="title"><p>이력서 열람기업</p></div>
		<div class="Board type3 reading">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="6%">
					<col width="30%">
					<col width="32%">
					<col width="32%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>열람일</th>
						<th>기업명</th>
						<th>담당자</th>
					</tr>
				</thead>
				<tbody>

					<tr v-for="(view, index) in list" :key="view.resumeViewHistorySeq">
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td>{{view.viewDate}}</td>
						<td>{{view.corpNm}}</td>
						<td>{{view.corpMberNm}}</td>
					</tr>

					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="4" class="none">이력서를 열람한 기업이 없습니다!</td>
					</tr>								

				</tbody>
			</table>

			<!--페이징 컴포넌트 -->
			<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
			<!--// 페이징 컴포넌트 -->

		</div>
	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
export default {
	components : { PagingComp },
	data() {
		return {
			input: {
				tecMberSeq: this.$route.query.tecMberSeq,
				pageIndex : '1',
			}, // 검색조건		
			list : [],
			pageInfo : {},
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			if(this.input.tecMberSeq){
				this.$.httpPost('/api/mem/prf/getResViewHistList', this.input)
					.then(res => {
						//console.log(res.data);
						this.list = res.data.list;
						this.pageInfo = res.data.pageInfo;
						this.input.checkedSeqList = [];

						this.$emit('searchEnd', res.data);
					}).catch(this.$.httpErrorCommon);
			}
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},
	},
};
</script>
